const VERSION = '0.1.0';
const FREE_ACCOUNT_LIMIT = 50;
const LOCAL_STORAGE_CONFIG_KEY = "scholar-accounts";
const SLP_PRICE_CONVERT_URL = "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25&vs_currencies=USD";
const AUTH_MESSAGE = "I understand that this is a community site and NOT affiliated with Axie Infinity in any way. Also that things may break at any time and I'm OK with that.";

function comparer(index) {
	return function(a, b) {
		let valA = getCellValue(a, index), valB = getCellValue(b, index);

		if (valA.includes("/") && valA.includes(":") && valB.includes("/") && valB.includes(":")) {
			let dateA = parseDateTime(valA);
			let dateB = parseDateTime(valB);
			return dateB - dateA;
		}

		return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB);
	};
}

function getCellValue(row, index) {
    return $(row).children('td').eq(index).text();
}

function parseDateTime(input) {
	let dateTimeParts = input.split(",");
	let dateParts = dateTimeParts[0].trim().split('/');
	let timeParts = dateTimeParts[1].trim().split(':');
	return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2]);
}

const hashstr = s => {
    let hash = 0;
    if (s.length == 0) return hash;
    for (let i = 0; i < s.length; i++) {
      let char = s.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

const cachedFetch = (url, options) => {
    let expiry = 5 * 60; // 5 min default

    if (typeof options === 'number') {
      expiry = options;
      options = undefined;
    } else if (typeof options === 'object') {
      // I hope you didn't set it to 0 seconds
      expiry = options.seconds || expiry;
    }

    let cacheKey = hashstr(url);
    let cached = localStorage.getItem(cacheKey);
    let whenCached = localStorage.getItem(cacheKey + ':ts');

    if (cached !== null && whenCached !== null) {
      let age = (Date.now() - whenCached) / 1000;

      if (age < expiry) {
          let response = new Response(new Blob([cached]));
          return Promise.resolve(response);
      } else {
          // We need to clean up this old key
          localStorage.removeItem(cacheKey);
          localStorage.removeItem(cacheKey + ':ts');
      }
    }

    return fetch(url, options).then(response => {
      // let's only store in cache if the content-type is JSON or something non-binary
      if (response.status === 200) {
        let ct = response.headers.get('Content-Type');
        if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
          // If we don't clone the response, it will be
          // consumed by the time it's returned. This
          // way we're being un-intrusive.
          response.clone().text().then(content => {
            localStorage.setItem(cacheKey, content);
            localStorage.setItem(cacheKey + ':ts', Date.now());
          });
        }
      }
      return response;
    });
}

async function auth() {
    if (typeof Cookies.get('auth_eth_addr') === 'undefined' || Cookies.get('auth_eth_addr') === '') {
        if (window.ethereum) {
            window.web3 = new Web3(ethereum);

            try {
                await ethereum.enable();

                web3.eth.getAccounts().then(accounts => {
                    if (accounts.length > 0) {
                        ethereumAddress = accounts[0];

                        web3.eth.personal.sign(AUTH_MESSAGE, ethereumAddress).then((signature) => {
                            login(ethereumAddress, AUTH_MESSAGE, signature).then(results => {
                                result = JSON.parse(results);
                                if (result.success) {
                                    unlock(ethereumAddress);
                                }
                            });
                        });
                    }
                });
            } catch (error) {
                // User denied account access
                console.log('User Denied Access');
            }
        } else if (window.web3) {
            window.web3 = new Web3(web3.currentProvider);

            web3.eth.getAccounts().then(accounts => {
                if (accounts.length > 0) {
                    ethereumAddress = accounts[0];

                    web3.eth.personal.sign(AUTH_MESSAGE, ethereumAddress).then((signature) => {
                        login(ethereumAddress, AUTH_MESSAGE, signature).then(results => {
                            result = JSON.parse(results);
                            if (result.success) {
                                unlock(ethereumAddress);
                            }
                        });
                    });
                }
            });
        } else {
            console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    } else {
        // Already logged in
        unlock(Cookies.get('auth_eth_addr'));
    }
}

async function savePersonalData(config) {
    let results;
	await $.post('/utils/savePersonalData.php', { config: config }).done(result => {
		results = result;
	});
    return results;
}

async function getPersonalData() {
    let results;
	await $.get('/utils/getPersonalData.php').done(result => {
		results = result;
	});
    return results;
}

async function login(ethereumAddress, message, signature) {
    let results;
	await $.get('/utils/login.php?addr=' + ethereumAddress + '&msg=' + message + '&sig=' + signature).done(result => {
		results = result;
	});
    return results;
}

async function logout() {
    let results;
	await $.get('/utils/logout.php').done(result => {
		results = result;
	});
    return results;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function fetchScholarData(ronin_address, getLeaderData = false) {
    let options = {
        method: 'GET',
        redirect: 'follow'
    };
	ronin_address_hex = ronin_address.replace("ronin:", "0x");

	let slpUrl = "https://game-api.skymavis.com/game-api/clients/" + ronin_address_hex + "/items/1";

    let slpResponse = await cachedFetch(slpUrl);
	if (!slpResponse.ok && slpResponse.status != 200) {
		return {
			'status': false,
            'ronin_address': ronin_address
		};
	}
	let slpOutput = await slpResponse.json();

	let timeNow = Math.floor(new Date().getTime() / 1000);
	let datediff = timeNow - slpOutput.last_claimed_item_at;
	let daysSinceClaimed = Math.round(datediff / (60 * 60 * 24));
	let slpPerDay = 0;
	let total = 0;
    let claimable_total = 0;
    let in_game_total = 0;

	if (daysSinceClaimed > 0) {
		total = slpOutput.total;
        claimable_total = slpOutput.claimable_total;
        in_game_total = total - claimable_total;

		slpPerDay = Math.round(in_game_total / daysSinceClaimed, 0);
	}

	if (getLeaderData) {
        await sleep(1000);

		let leaderUrl = "https://game-api.skymavis.com/game-api/leaderboard?client_id=" + ronin_address_hex + "&offset=0&limit=0";

        let leaderResponse = await cachedFetch(leaderUrl, options);
		if (!leaderResponse.ok && leaderResponse.status != 200) {
			return {
				'status': false,
                'ronin_address': ronin_address
			};
		}
		let leaderOutput = await leaderResponse.json();

		let rankData = [];
		if (leaderOutput.items.length > 0) {
			leaderOutput.items.forEach(item => {
				if (item.client_id === ronin_address_hex) {
					rankData = item;
				}
			});
		}

		return {
			'status': true,
            'ronin_address': ronin_address,
			'rank': rankData,
			'total': total,
			'next_claimed_item_at': slpOutput.last_claimed_item_at + 1209600, // 2 weeks in seconds
			'slp_per_day': slpPerDay
		};
	} else {
		return {
			'status': true,
            'ronin_address': ronin_address,
			'rank': [],
			'total': total,
			'next_claimed_item_at': slpOutput.last_claimed_item_at + 1209600, // 2 weeks in seconds
			'slp_per_day': slpPerDay
		};
	}
}

function isValidInputScholar(scholar_name, ronin_address, manager_cut, config) {
    let found = false;

    for (let i = 0; i < config.length; i++) {
        if (config[i].ronin_address == ronin_address) {
            found = true;
        }
    }

    let fieldStatus = (scholar_name.length > 0 && ronin_address.length > 0 && manager_cut.length > 0 && manager_cut >=0 && manager_cut <= 100);

    if (fieldStatus && !found) {
        return true;
    } else {
        return false;
    }
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
